import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser";
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/UI/PageTitle"
import Icon from "../components/UI/Icon";
import { Tab } from '@headlessui/react'
import { Disclosure } from '@headlessui/react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'


const Context = React.createContext({});

function Accordion({ children }) {
  const [selected, setSelected] = React.useState();

  const toggleItem = React.useCallback(
    (id) => () => {
      setSelected((prevState) => (prevState !== id ? id : ''));
    },
    [],
  );
  return (
    <Context.Provider value={{ selected, toggleItem }}>
      {children}
    </Context.Provider>
  );
}

//custom hook to consume all accordion values
const useAccordion = () => React.useContext(Context);

function AccordionItem({ toggle, children }) {
  const { selected, toggleItem } = useAccordion();
  return (
    <button onClick={toggleItem(toggle)} className={"focus:outline-none w-full rounded-full border border-gray-200 py-3 px-4 flex justify-between items-center hover:text-primary-dark hover:border-primary-dark" + (selected === toggle ? " text-primary" : "")}>
      <h2>{children}</h2>
      <Icon icon="chevron-up" className={"text-primary transition duration-500 ease-in-out transform" + (selected === toggle ? " rotate-0" : " rotate-180")} />
    </button>
  );
}

function AccordionPanel({ children, id }) {
  const { selected } = useAccordion();
  const ref = React.useRef();
  const inlineStyle =
    selected === id ? { height: ref.current?.scrollHeight } : { height: 0 };

  return (
    <div ref={ref} id={id} className="px-6 overflow-hidden md:overflow-x-hidden transition-height ease duration-300" style={inlineStyle}>
      {children}
    </div>
  );
}

const MenuPage = () => {
  const data = useStaticQuery(
    graphql`
      query MenuAccordionQuery {
        contentJson(fileID: {eq: "menu"}) {
          fileID
          menuIntro
          menuSections {
            sectionName
            sectionText
            sectionPrice
            sectionCat {
              catTitle
              catText
              catPrice
              catItems {
                name
                desc
                price
              }
            }
          }
        }
      }
    `
  )
  //const content = data.contentJson
  useFirestoreConnect([
    { collection: 'menu', doc: 'origin-cafe' }
  ])
  const content = useSelector(({ firestore: { data } }) => data.menu && data.menu['origin-cafe'])
  if (typeof content === 'undefined') {
    return null
  }
  return (
    <Layout>
      <Seo title="Menu" />
      <div className="flex-grow">
        <PageTitle title="Menu" intro={data.contentJson.menuIntro} />

        <div className="container mx-auto">

        <Tab.Group>
          <Tab.List>
            {content.menuSections.map((section, i) => (
              <Tab as={Fragment} key={i}>
                {({ selected }) => (
                  <button className={"btn btn-primary btn-sm mr-4" + (selected ? "" : " btn-outline")}>{section.sectionName}</button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {content.menuSections.map((section, i) => (
              <Tab.Panel key={i}>
                {(section.sectionText || section.sectionPrice) && (
                  <div className="pt-6">
                  {section.sectionText && (
                    <div className="text-sm">{section.sectionText}</div> 
                  )}
                  {section.sectionPrice && (
                    <div className="font-bold pt-2">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XPF' }).format(section.sectionPrice)}</div> 
                  )}
                  </div>
                )}                
                
                <div className="pt-4">
                  <Accordion>
                  {section.sectionCat.map((item, i) => (
                    <div key={i} className="my-4">
                      <AccordionItem toggle={"section-" + i}>{item.catTitle}</AccordionItem>
                      <AccordionPanel id={"section-" + i}>
                        <div className="pt-2">
                          {item.catText && (
                            <div className="text-sm text-center pt-4">{parse(item.catText)}</div>
                          )}
                          {item.catPrice && (
                            <div className="text-center font-bold pt-2">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XPF' }).format(item.catPrice)}</div> 
                          )}  
                          <div className="divide-y divide-gray-100">
                            {item.catItems.map((catItem, index) => (
                              <div className="sm:flex sm:justify-between py-4" key={"catItem-" + index}>
                                <div>
                                  <div className="font-bold">{catItem.name}</div>
                                  {catItem.desc && (
                                    <div className="text-sm">{parse(catItem.desc)}</div>
                                  )}
                                </div>
                                {catItem.price && (
                                  <div>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XPF' }).format(catItem.price)}</div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </AccordionPanel>
                    </div>
                  ))}
                  </Accordion>
                </div>

              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>

        <div className="mt-20">
          <p className="mb-4">Ceci est un test Disclosure HeadlessUI</p>
          <div className="p-4 bg-gray-200">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                    <span>What is your refund policy?</span>
                    <Icon icon="chevron-up" className={"text-primary transition duration-500 ease-in-out transform" + (open ? " rotate-0" : " rotate-180")} />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    If you're unhappy with your purchase for any reason, email us
                    within 90 days and we'll refund you in full, no questions asked.
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                    <span>Do you offer technical support?</span>
                    <Icon icon="chevron-up" className={"text-primary transition duration-500 ease-in-out transform" + (open ? " rotate-0" : " rotate-180")} />
                  </Disclosure.Button>
                  <Disclosure.Panel className={"px-4 pt-4 pb-2 text-sm text-gray-500"}>
                    No.
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>

        </div>
      </div>
    </Layout>
  )
}

export default MenuPage